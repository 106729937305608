.missionDetailsManagementContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;
  height: 65%;
  top: 25%;
  left: 7.5%;
}

.missionDetailsManagementReturnSection,
.missionDetailsManagementContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.missionDetailsManagementReturnSection {
  height: 5%;
}

.missionDetailsManagementReturnButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.missionDetailsManagementReturnButton:hover {
  text-decoration: underline;
}

.missionDetailsManagementContent {
  height: 95%;
}

.missionDetailsManagementEmployeesContainer,
.missionDetailsManagementDocumentsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-inline: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 49.75%;
  height: 100%;
}

.missionDetailsManagementEmployeesContainerTitle,
.missionDetailsManagementEmployeesContainerTitle {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 5%;
}

.missionDetailsManagementEmployeesContainerContent,
.missionDetailsManagementDocumentsContainerContent {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
}

.missionDetailsManagementEmployeesContainerButton,
.missionDetailsManagementDocumentsContainerButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 15%;
}

.missionDetailsManagementEmployeesLinked {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  max-height: 90%;
  margin-top: 2%;
  overflow-y: auto;
}

.employeeSelector {
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: none;
  padding: 2%;
  margin-left: 2%;
}

.employeesLinkedSorted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.employeeLinked {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5%;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 50%;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-top: 2%;
}

.missionDocumentFileName,
.missionDocumentFileType {
  box-sizing: border-box;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
  padding: 2%;
  margin-bottom: 3%;
}

.missionDocumentFileName {
  width: 78%;
}

.missionDocumentFileType {
  width: 20%;
}

.fileUploaderMission {
  height: 80%;
  margin-bottom: 2%;
}

.iframeMission {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  margin-bottom: 2%;
}

.missionDetailsManagementAddDocumentsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
