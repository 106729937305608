.fileUploaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: justify;
  width: 100%;
  height: 100%;
}

.dropzoneStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px dashed #cccccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
