.pages,
.popup {
  margin: 0;
  font-family: 'Muli-Regular', 'DrukTextWide-Bold', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Muli-Regular';
  src: url('./assets/fonts/Muli/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DrukTextWide-Bold';
  src: url('./assets/fonts/DrukText/DrukTextWide-Bold.otf') format('opentype');
}

.pages p,
.pages pre,
.pages span,
.pages div,
.pages button,
.pages option,
.pages select,
.popup p,
.popup pre,
.popup span,
.popup div,
.popup button,
.popup option,
.popup select {
  font-family: 'Muli-Regular', sans-serif;
}

.pages h1,
.pages h2,
.pages h3,
.pages h4,
.pages h5,
.pages h6,
.popup h1,
.popup h2,
.popup h3,
.popup h4,
.popup h5,
.popup h6 {
  font-family: 'DrukTextWide-Bold', sans-serif;
}

.pages #obligatoire,
.popup #obligatoire {
  color: red;
}

._IYz6Z {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

._4GdcU {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: small;
}

._4GdcU:hover {
  cursor: text;
  background-color: transparent;
}

@media screen and (max-width: 1536px) and (max-height: 826px) {
  ._4GdcU {
    font-size: x-small;
  }
}
