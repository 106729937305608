.patchNotesContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 65%;
  top: 25%;
  left: 5%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 150%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 2%;
  overflow-y: auto;
}

.patchNotesSubtitles ul {
  margin-bottom: 2%;
}
