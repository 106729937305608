.employeesContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 70%;
  top: 22.5%;
  left: 25%;
}

.addEmployeeButtonContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  height: 5%;
}

.addEmployeeButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.addEmployeeButton:hover {
  text-decoration: underline;
}

.addEmployeeLogo {
  margin-left: 10px;
  width: 12.5px;
  height: 12.5px;
}

.employeesHeaderContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  height: 10%;
  padding-inline: 0.5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.employeesFirstName,
.employeesLastName,
.employeesCivility,
.employeesDateOfBirth,
.employeesNationality {
  display: flex;
  box-sizing: border-box;
  font-size: small;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 19%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.employeesBodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 75%;
}

.employeesFooterContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-inline: 2%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #ed6723;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.employeesFooterContainer span {
  box-sizing: border-box;
  padding-inline: 1%;
}

.employeesFooterContainer button {
  background-color: transparent;
  border: none;
  color: #ed6723;
}

.employeesFooterContainer button:enabled {
  cursor: pointer;
}

.employeesFooterContainer button:disabled {
  color: lightgray;
}

.employeeContainer,
.noEmployeeRegistered {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 35%;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.noEmployeeRegistered {
  justify-content: center;
  align-items: center;
}

.employeeFirstName,
.employeeLastName,
.employeeCivility,
.employeeDateOfBirth,
.employeeNationality {
  display: flex;
  box-sizing: border-box;
  padding-inline: 2%;
  height: 100%;
  font-size: small;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 19%;
}

.employeeDetailsButton {
  width: 5%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.openEmployeeDetails {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.employeeDetailsContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  height: 65%;
  top: 25%;
  left: 7.5%;
}

.employeeDetailsGlobalContainer,
.employeeDetailsDocumentsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  padding-inline: 2%;
  padding-top: 1.5%;
  padding-bottom: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.employeeDetailsGlobalContainer {
  margin-right: 0.5%;
}

.employeeDetailsDocumentsContainer {
  margin-left: 0.5%;
}

.globalInformationContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.globalInformationContentSubzoneTop,
.globalInformationContentSubzoneBottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 1%;
  font-size: 14px;
}

.globalInformationContentSubzoneTop {
  height: 55%;
}

.globalInformationContentSubzoneBottom {
  height: 45%;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .employeesContainer {
    width: 75%;
    left: 12.5%;
  }

  .employeesContainer p {
    font-size: x-small;
  }

  .employeesFooterContainer {
    font-size: 10px;
  }

  .employeeContainer {
    height: 75px;
  }

  .employeeContainer p {
    font-size: x-small;
  }
}
