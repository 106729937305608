.uploadConfirmationStyle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #02a388;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.uploadConfirmationStyle.fadeIn {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.uploadConfirmationStyle.fadeOut {
  opacity: 0;
  transition: opacity 1s ease-out;
}
