.orderFormContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 65%;
  top: 22.5%;
  left: 10%;
}

.orderFormDescription {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  text-align: justify;
  margin-bottom: 1%;
}

.orderFormContainerDetails {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.orderFormContainerLeft {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.orderFormNavigationButtons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  height: 25%;
}

.formButton {
  width: 25%;
  height: 45%;
  background-color: #ed6723;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  font-size: medium;
  cursor: pointer;
  color: white;
  margin-left: 5%;
  margin-right: 5%;
}

.formButton p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error p {
  color: red;
}

.scroller {
  overflow-y: auto;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.orderFormContainerRight {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 82.5%;
  margin-top: 2.5%;
  margin-left: 5%;
  margin-right: 5%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 150%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.orderFormContainerRight h2 {
  box-sizing: border-box;
  padding-top: 5%;
  display: flex;
  justify-content: center;
}

.orderFormContainerRight p {
  margin-left: 5%;
  margin-right: 5%;
  font-size: smaller;
}

.orderFormSectors,
.orderFormCountries,
.orderNumberOfEmployees,
.orderDetailsMissionType,
.orderFormDatePicker,
.orderFormInformationDocument {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.orderDetailsMissionTypeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.orderConstructionCard,
.orderConstructionCardOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.orderDetailsRadioButton input,
.orderDetailsRadioButton label {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.orderConstructionCard {
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.orderConstructionCardOptions {
  flex-direction: row;
}

.orderFormSectors p {
  box-sizing: border-box;
  padding-top: 1.75%;
}

.orderFormContainer hr {
  width: 80%;
  height: none;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.horizontRightContent,
.mention {
  display: flex;
  flex-direction: row-reverse;
}

.mention {
  font-size: 9px;
  margin-right: 5%;
  margin-left: 5%;
  text-align: right;
}

.content-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.datePicker {
  width: 200px;
  height: 40px;
  color: black;
  cursor: pointer;
  font-size: small;
  align-items: center;
  background-color: white;
  margin-left: 2%;
  margin-right: 5%;
  .react-date-picker__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 5%;
  }
}

@media screen and ((max-width: 1536px) or (max-height: 791)) {
  .orderFormContainerRight h2 {
    font-size: 16px;
  }
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .orderFormContainer {
    width: 95%;
    left: 2.5%;
  }

  .orderFormContainer p,
  .orderFormContainer label {
    font-size: small;
  }

  .orderFormContainerRight h2 {
    font-size: 16px;
  }

  .orderFormContainerLeft {
    width: 50%;
  }

  .orderFormContainerRight {
    width: 50%;
  }

  .orderFormContainerRight p {
    font-size: x-small;
  }

  .formButton {
    width: 35%;
  }

  .react-calendar {
    max-width: 80%;
    line-height: 80%;
  }
}

.sectorsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: auto;
}

.sectorsButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: auto;
  cursor: pointer;
  padding-top: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  border: none;
  background-color: transparent;
  margin-left: 5%;
  margin-right: 5%;
}

.sectorsButton.clicked {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.sectorsButton:hover {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.orderFormCountriesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 75%;
}

.rowcondition {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-dropdown-country {
  margin-right: 10%;
}

.content-dropdown-button {
  width: 200px;
  height: 40px;
  color: black;
  cursor: pointer;
  font-size: small;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 5px;
  padding: 5%;
}

.content-dropdown-button option {
  background-color: white;
  color: black;
}

.content-dropdown-button option:checked {
  background-color: lightgray;
  color: black;
}

.content-dropdown-button::-webkit-scrollbar {
  width: 6px;
}

.content-dropdown-button::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .content-dropdown-button {
    font-size: small;
  }

  .orderFormCountriesContainer {
    flex-direction: column;
  }
}

.orderDetailsRadioButton {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
}

.orderDetailsRadioButton input[type='radio'] {
  accent-color: #ed6723;
}

.tooltip {
  width: 2.5%;
  height: auto;
  margin-left: 2%;
  font-size: 2px;
}

.orderNumberOfEmployees {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}

.employeesDropdown {
  color: black;
  cursor: pointer;
  font-size: small;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 5px;
  padding: 1%;
}

.employeesDropdown::-webkit-scrollbar {
  width: 6px;
}

.employeesDropdown::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.employeesDropdown option {
  background-color: white;
  color: black;
}

.employeesDropdown option:checked {
  background-color: lightgray;
  color: black;
}

.orderFormDatePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 75%;
}

.rowconditionDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.informationDocumentsContainer {
  box-sizing: border-box;
  padding-inline-start: 10%;
  padding-block-end: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: first baseline;
  width: 100%;
}

.informationDocumentScroller {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
}

.informationDocumentScroller input {
  accent-color: #ed6723;
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.informationDocumentScroller input[id='fr'] {
  accent-color: grey;
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: auto;
}

.informationDocumentScroller label {
  margin-right: 20px;
  margin-bottom: 10px;
}

.informationDocumentScroller::-webkit-scrollbar {
  width: 6px;
}

.informationDocumentScroller::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.informationDocumentsDownArrow {
  visibility: hidden;
  width: 10px;
  height: 10px;
}

.choosableOptions {
  display: flex;
  flex-direction: column;
}

.orderFormLocation,
.orderFormLodging,
.orderFormEndCustomer,
.orderFormPaymentMethod {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.orderFormLocationHeader i,
.orderFormLodging i,
.orderFormPaymentMethod {
  margin-bottom: 2%;
}

.orderFormLocationHeader,
.orderFormLodgingHeader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.locationTooltip,
.lodgingTooltip {
  width: 20px;
  height: 20px;
  margin-left: 2%;
  font-size: 2px;
}
.subBlocks,
.subBlocksAdditional,
.subBlocksPostal,
.subBlocksBottom {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5%;
  padding-inline: 1%;
}

.subBlocks {
  width: 100%;
}

.subBlocksAdditional {
  width: 65%;
}

.subBlocksPostal {
  width: 35%;
}

.subBlocksBottom {
  width: 50%;
}

.locationAddressInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.locationName,
.locationMainAddress,
.locationAdditionalAddress,
.locationCountry,
.locationPostalCode,
.locationCity {
  box-sizing: border-box;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
}

.locationName,
.locationMainAddress {
  width: 100%;
  padding: 2%;
}

.locationAdditionalAddress {
  width: 100%;
  padding: 3%;
  margin-right: 0.5%;
}

.locationCountry {
  width: 100%;
  margin-left: 0.5%;
}

.locationSelectorCountry {
  box-sizing: border-box;
  padding: 3.25%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 12px;
  accent-color: #ed6723;
  background-color: white;
  cursor: pointer;
}

.locationSelectorCountry::-webkit-scrollbar {
  width: 6px;
}

.locationSelectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.locationPostalCode {
  width: 100%;
  padding: 5.25%;
  margin-left: 0.5%;
}

.locationCity {
  width: 100%;
  padding: 4%;
  margin-right: 0.5%;
}

.lodgingStartDate,
.lodgingEndDate,
.lodgingType {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.lodgingStartDate,
.lodgingEndDate {
  width: 100%;
  padding-top: 2%;
}

.lodgingType {
  width: 80%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.lodgingDatePickerStart,
.lodgingDatePickerEnd {
  width: 33%;
  height: 33%;
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  .react-date-picker__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    padding: 5%;
  }
}

.lodgingDatePickerStart {
  padding-left: 4%;
}

.lodgingDatePickerEnd {
  padding-left: 4%;
  margin-left: 2.5%;
}

.lodgingSelector {
  box-sizing: border-box;
  padding: 3%;
  margin-left: 2.5%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: fit-content;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
}

.lodgingSelectorContainer {
  width: 75%;
  height: 100%;
}

.lodgingAddress {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.paymentMethodButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.warningContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 70%;
}

.warningText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 70%;
  text-align: center;
}

.returnButton {
  font-size: 20px;
  color: #ed6723;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.paymentButton1,
.paymentButton2 {
  display: flex;
  box-sizing: border-box;
  width: 60%;
  height: fit-content;
  padding: 3%;
  background-color: #ed6723;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: white;
}

.paymentButton1 {
  margin-bottom: 2%;
}

.paymentButton2 {
  margin-top: 2%;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .informationDocumentsContainer {
    padding-inline-start: 5%;
  }

  .informationDocumentScroller {
    flex-direction: column;
    overflow-y: auto;
  }
  .informationDocumentsDownArrow {
    visibility: visible;
  }

  .responsiveAlignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
