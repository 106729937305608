.employeeDocumentsList {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2%;
}

.employeeConstructionCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2%;
}

.pdfViewer,
.pdfViewerButtons,
.pdfViewerButton {
  margin-top: 1%;
}

.pdfViewer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.iframe {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.pdfViewerButtons {
  display: flex;
  flex-direction: column;
}

.pdfViewerButton {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 40%;
}

.pdfViewerBlocks {
  box-sizing: border-box;
  display: flex;
  padding-top: 2%;
  justify-content: space-between;
  width: 100%;
}

.pdfViewerFields {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 49%;
}

.pdfViewerEditText,
.pdfViewerSelectorDate {
  box-sizing: border-box;
  margin-top: 2%;
  padding: 1%;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.pdfViewerSelectorDate {
  height: 100%;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

.saveButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.saveButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.saveButton:hover {
  text-decoration: underline;
}

.saveLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.space {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
