.inspectionPopupContainer,
.companyPopupContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1%;
}

.inspectionBlockGlobal,
.inspectionBlockProofs {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 1%;
  margin: 1%;
}

.inspectionBlockGlobal {
  height: 25%;
}

.inspectionBlockProofs {
  height: 100%;
}

.inspectionButtonsContainer,
.companyButtonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.inspectionError,
.companyError {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inspectionError p,
.companyError p {
  color: red;
}

.inspectionNextButtonContainer,
.companyNextButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 3.5%;
}

.inspectionNextButton,
.companyNextButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.inspectionNextButton:hover,
.companyNextButton:hover {
  text-decoration: underline;
}

.inspectionBackButtonContainer,
.companyBackButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: 3.5%;
}

.inspectionBackButton,
.companyBackButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.inspectionBackButton:hover,
.companyBackButton:hover {
  text-decoration: underline;
}

.inspectionTypeOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
}

.inspectionTypeOptions label,
.inspectionTypeOptions input[type='radio'] {
  cursor: pointer;
}
.inspectionTypeOptions label {
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: center;
  width: 33%;
}
.inspectionTypeOptions input[type='radio'] {
  accent-color: #ed6723;
}

.inspectionEditableOptions,
.inspectionEditText {
  box-sizing: border-box;
  padding: 1%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
  .react-edit-text___IYz6Z {
    border: none;
  }
}

.inspectionEditableOptions {
  width: fit-content;
}

.inspectionEditText {
  width: 90%;
}

.inspectionTimeSelector {
  box-sizing: border-box;
  padding-inline: 1%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
  border: none;
}

.inspectorInformationBlock,
.companyInformationBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1%;
  margin: 1%;
}

.inspectionSubblock,
.companySubblock {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.companyPopupFieldToUpdate {
  width: 100%;
  padding-bottom: 5%;
}

.companySubtitle {
  font-size: small;
  font-style: italic;
}

.companyEditText,
.companyEditTextFieldHalfSize,
.companyEditTextFieldHalfSizeDate {
  box-sizing: border-box;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 12px;
  background-color: white;
}

.companyEditText {
  width: 45%;
  padding: 2%;
}

.companyEditTextFieldHalfSize,
.companyEditTextFieldHalfSizeDate {
  width: 90%;
}

.companyEditTextFieldHalfSize {
  padding: 4%;
}

.companyEditTextFieldHalfSizeDate {
  padding: 1.75%;
  .react-date-picker__wrapper {
    border: none;
    padding: 1%;
  }
}

.companyEditText option {
  background-color: white;
  color: black;
}

.companyEditText option:checked {
  background-color: lightgray;
  color: black;
}

.companyEditText::-webkit-scrollbar {
  width: 6px;
}

.companyEditText::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.companyEditAndTooltipContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.companyAddressInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.companyMainAddress,
.companyAdditionalAddress,
.companyCountry,
.companyPostalCode,
.companyCity {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.companyMainAddress {
  width: 100%;
}

.companyAdditionalAddress {
  width: 100%;
  margin-right: 0.5%;
}

.companyCountry {
  width: 100%;
  margin-left: 0.5%;
  padding-inline: 0%;
}

.companySelectorCountry {
  box-sizing: border-box;
  padding: 2%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
  cursor: pointer;
}

.companySelectorCountry::-webkit-scrollbar {
  width: 6px;
}

.companySelectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.companyPostalCode {
  width: 100%;
  margin-left: 0.5%;
  padding: 1.75%;
}

.companyCity {
  width: 100%;
  margin-right: 0.5%;
}

.subtitlesBlocks,
.subtitlesBlocksAdditional,
.subtitlesBlocksPostal,
.subtitlesBlocksBottom {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5%;
  padding-inline: 1%;
}

.subtitlesBlocks {
  width: 100%;
}

.subtitlesBlocksAdditional {
  width: 65%;
}

.subtitlesBlocksPostal {
  width: 35%;
}

.subtitlesBlocksBottom {
  width: 50%;
}

.updateAddresses {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.inlineBlocks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.createEmployeePopup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.civilityBlock {
  display: flex;
  flex-direction: row;
  accent-color: #ed6723;
}

.civilityOptionsLabel {
  margin-left: 4%;
}

.namesAndNationalityBlock,
.birthBlock,
.createEmployeeAddressBlock {
  display: flex;
  justify-content: space-between;
}

.namesAndNationalityBlock,
.birthBlock {
  flex-direction: row;
}

.createEmployeeAddressBlock {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.namesAndNationalityBlockFields,
.birthBlockFields {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  padding-top: 3%;
}

.firstName,
.lastName,
.nationality,
.selectorBirthDate,
.birthPlace,
.selectorBirthCountry {
  box-sizing: border-box;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
}

.firstName,
.lastName,
.nationality,
.birthPlace {
  padding: 5%;
}

.selectorBirthDate {
  padding: 2%;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

.selectorBirthCountryDiv {
  box-sizing: border-box;
  display: flex;
  height: 100%;
}

.selectorBirthCountry {
  width: 100%;
  padding-inline-start: 3%;
}

.selectorBirthCountry::-webkit-scrollbar {
  width: 6px;
}

.selectorBirthCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.employeeMainAddress,
.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  box-sizing: border-box;
  display: flex;
  padding-top: 3%;
  justify-content: space-between;
}

.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  flex-direction: row;
}

.employeeMainAddress {
  width: 100%;
}

.employeeAdditionalAddress {
  width: 57.5%;
}
.employeePostalCode {
  width: 37.5%;
}

.employeeCountry,
.employeeCity {
  width: 47.5%;
}

.employeeCountryOfBirth {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.employeeCountry {
  box-sizing: border-box;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.secondBlockFieldsEditText,
.thirdBlockFieldsEditText,
.fourthBlockFieldsEditText,
.selectorCountry,
.employeeMainAddressEditText,
.employeeAdditionalAddressEditText,
.employeePostalCodeEditText,
.employeeCityEditText {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 12px;
  background-color: white;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .createEmployeePopup p,
  .createEmployeePopup label {
    font-size: 12px;
  }
}

@media screen and (max-width: 1536px) and (max-height: 913px) {
  .companyPopupContainer,
  .companySubtitle {
    font-size: 12px;
  }

  .companyBackButtonContainer,
  .companyNextButtonContainer {
    padding-top: 0%;
  }
}
