.popup {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.popup-inner {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 75%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.popup-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-top: 2%;
  padding-inline: 3%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;
}

.popup-header h1 {
  font-size: 150%;
}

.popup-header img {
  width: 2.5%;
  height: auto;
  cursor: pointer;
}

.popup-body {
  box-sizing: border-box;
  padding-inline: 4%;
  width: 100%;
  height: 85%;
}

.popup-footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2%;
  width: 100%;
  height: 10%;
}

.saveButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
  vertical-align: middle;
}

.saveButton:hover {
  text-decoration: underline;
}

.saveLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .popup-header h1 {
    font-size: 100%;
  }
}
