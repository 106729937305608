.footerContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background-image: url('../../assets/images/backgrounds/background_social.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.aboutButton {
  left: 0;
  margin-left: 2%;
}

.copyright {
  text-align: center;
  color: white;
  font-size: small;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footerButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  top: 50%;
  color: white;
  font-size: small;
  margin: 0 auto;
  position: absolute;
  transform: translate(0%, -50%);
}

.footerButtonContainer:hover {
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: 1.5px;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .footerContainer p {
    font-size: 10px;
  }
}
