.contentContainer {
  position: relative;
  width: 100%;
  height: 85%;
}

.contentContainer h1 {
  text-align: center;
  padding: 1.5%;
}

.contentButton {
  position: fixed;
  background-color: #ed6723;
  border-radius: 10px;
  border: none;
  font-size: medium;
  padding: 0.75% 1.75%;
  cursor: pointer;
  top: 12%;
  left: 2%;
  color: white;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .contentContainer h1 {
    font-size: 20px;
    padding: 0.75%;
  }

  .contentButton {
    font-size: 12px;
  }
}
