.endCustomerMissionDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.endCustomerMissionDetailsUpdatableFields {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin-bottom: 5%;
  padding-right: 1%;
  overflow-y: auto;
}

.endCustomerMissionDetailsUpdateCompanyName,
.endCustomerMissionDetailsUpdateMailAddress,
.endCustomerMissionDetailsUpdateVatNumber,
.endCustomerMissionDetailsUpdateSiren {
  box-sizing: border-box;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
  width: 100%;
  padding: 2%;
  margin-bottom: 3%;
}

.endCustomerMissionDetailsUpdateAddress {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  margin-bottom: 3%;
}

.endCustomerMissionDetailsFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 10%;
}

.endCustomerMissionDetailsFieldsError {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.endCustomerMissionDetailsFieldsError p {
  color: red;
}

.endCustomerMissionDetailsSaveButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.endCustomerMissionDetailsSaveButton {
  background-color: #ed6723;
  border: #ed6723;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: white;
  padding: 10px;
  height: 75%;
  margin-left: 5px;
}

.endCustomerMissionDetailsSaveButton:hover {
  text-decoration: underline;
}

.endCustomerMissionDetailsSaveLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .endCustomerMissionDetailsContainer p {
    font-size: 12px;
  }
}
