.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-button,
.dropdown-button-create-company {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: large;
  display: flex;
  align-items: center;
}

.dropdown-button {
  color: white;
}
.dropdown-button-create-company {
  color: black;
}

.dropdown-button p,
.dropdown-button-create-company p {
  padding-left: 10px;
  padding-right: 10px;
  font-size: x-small;
}

.dropdown-options-left,
.dropdown-options-right {
  position: absolute;
  top: 100%;
  width: max-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;
  padding: 10%;
  margin-top: 10%;
  border-radius: 7.5px;
  z-index: 10;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.dropdown-options-right {
  right: 0;
  margin-right: 10px;
  width: max-content;
}

.dropdown-option {
  padding: 5px;
  cursor: pointer;
  font-size: medium;
  display: flex;
  align-items: center;
}

.dropdown-option:hover {
  background-color: lightgray;
  border-radius: 7.5px;
}

.languageContainer {
  display: flex;
  align-items: center;
}

.flagEmoji {
  margin-right: 5px;
  width: 30px;
  height: auto;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .dropdown-button,
  .dropdown-button-create-company {
    font-size: 14px;
  }

  .dropdown-button p,
  .dropdown-button-create-company p {
    font-size: xx-small;
  }

  .dropdown-option {
    font-size: 12px;
  }

  .flagEmoji {
    width: 20px;
  }
}
