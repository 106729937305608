.additionalAddressAndPostalCode,
.cityAndCountry {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 33%;
  margin-top: 1%;
}

.mainAddress,
.additionalAddress,
.postalCode,
.city,
.country {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 33%;
}

.additionalAddress,
.city {
  margin-right: 0.5%;
}

.postalCode,
.country {
  margin-left: 0.5%;
}

.mainAddress {
  width: 100%;
}

.additionalAddress {
  width: 70%;
}

.postalCode {
  width: 30%;
}

.city,
.country {
  width: 50%;
}

.mainAddressTextInput,
.additionalAddressTextInput,
.postalCodeTextInput,
.cityTextInput,
.countrySelector {
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.mainAddressTextInput,
.additionalAddressTextInput,
.postalCodeTextInput,
.cityTextInput {
  padding: 15px;
}

.countrySelector {
  padding: 14px;
}
