.excelViewerInput {
  display: none;
}

.excelViewerLabel {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  background-color: #f0f0f0;
  margin-bottom: 2%;
  cursor: pointer;
}

.excelViewerContainer {
  width: 100%;
  height: 350px;
  overflow: auto;
  border: 1px solid #ccc;
  margin-bottom: 2%;
  margin-top: 2%;
}

.excelViewerTable {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

.excelViewerTh {
  border: 1px solid black;
  padding: 5px;
  background-color: #f0f0f0;
}

.excelViewerTd {
  border: 1px solid black;
  padding: 5px;
  text-align: left;
  white-space: nowrap;
}
