.navbarContainer {
  display: flex;
  justify-content: space-between;
  background-color: none;
  width: 100%;
  margin-right: 2%;
}

.navbarLeftContainer {
  display: flex;
  align-items: center;
  background-color: none;
  width: fit-content;
}

.navbarRightContainer {
  display: flex;
  align-items: center;
  background-color: none;
  width: fit-content;
  position: right;
}

.moreButton {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: large;
  display: flex;
  align-items: center;
}

.moreButton:hover {
  text-decoration: underline;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .moreButton {
    font-size: 14px;
  }
}
