.dashboardContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 60%;
  top: 30%;
  left: 10%;
}

.dashboardContainerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
  font-size: medium;
  margin-top: 1%;
}

.dashboardContainerTop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
}

.dashboardContainerBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
}

.dashboardContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.dashboardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 50%;
  background-color: #ed6723;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: medium;
  color: white;
  padding: 1.5%;
  margin: 1%;
}

.dashboardButton img {
  width: 17.5px;
  margin-right: 2%;
}

.dashboardContainerInformation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  height: 95%;
  margin: 0.25%;
  padding: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 150%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.dashboardContainerInformation h1 {
  font-size: 250%;
}

.informationButton {
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: black;
  text-decoration: underline;
  font-size: medium;
}

.informationButton:hover {
  color: #ed6723;
}

@media screen and ((max-width: 1536px) or (max-height: 913px)) {
  .dashboardButton {
    width: 20%;
    height: 75%;
  }
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .dashboardButton {
    font-size: 12px;
    width: 20%;
    height: 75%;
  }

  .dashboardButton img {
    width: 8.75px;
  }

  .dashboardContainerInformation h1 {
    font-size: 200%;
  }

  .informationButton {
    font-size: 12px;
  }
}
