.companyInformationContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 65%;
  top: 22.5%;
  left: 25%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.companyInformationContainer hr {
  width: 80%;
  height: none;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.companyInformationContainer a {
  cursor: pointer;
  color: #ed6723;
  padding: 0.5%;
}

.companyInformationContainer a:hover {
  text-decoration: underline;
  color: #ed6723;
}

.topContainer,
.middleContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 2%;
  padding-inline: 2%;
}

.topContainer {
  height: 27.5%;
}

.middleContainer {
  height: 37.5%;
}

.bottomContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 32%;
  padding-top: 2%;
  padding-inline: 2%;
}

.bottomContainerBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainActivitySectorContainer {
  width: 15%;
  margin-right: 1.25%;
}

.mainActivitySector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.mainActivitySector:hover {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.mainActivitySector.clicked {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.mainActivitySectorOptions {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.mainActivitySectorOption {
  cursor: pointer;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-size: smaller;
}

.mainActivitySectorOption:hover {
  background-color: lightgray;
  border-radius: 7.5px;
}

.arrow {
  width: 7.5px;
  height: 7.5px;
}

.globalInformationZone,
.sipsiZone {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*width: 85%;
  margin-left: 1.25%;*/
}

.directorZone {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.sectionsEntitled,
.sectionsTitle {
  width: fit-content;
  height: fit-content;
}

.sectionsTitle {
  font-size: small;
}

.addressesDetails,
.directorDetails {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.globalCompanyDetailsContainer {
  box-sizing: border-box;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.globalCompanyDetails,
.contactDetails {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.contactDetails {
  padding-inline: 1%;
}

.addressesDetailsBlock,
.directorDetailsBlock,
.sipsiDetailsBlock {
  box-sizing: border-box;
  padding-inline: 1%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.deliveryAddress,
.billingAddress {
  box-sizing: border-box;
  height: 100%;
}

.deliveryAddress {
  width: 100%;
}

.billingAddress {
  width: 50%;
  padding-inline: 0.5%;
  margin-left: 0.5%;
}

.sipsiAccount {
  box-sizing: border-box;
  padding-inline: 1%;
  width: 30%;
  height: 100%;
  margin-left: 5%;
}

.sameAddress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  accent-color: #ed6723;
  font-size: x-small;
}

.deliveryAddressHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addressInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  margin-bottom: 1.5%;
}
/*
.mainAddress,
.additionalAddress,
.country,
.postalCode,
.city {
  box-sizing: border-box;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  background-color: white;
}

.mainAddress,
.additionalAddress,
.postalCode,
.city {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.mainAddress {
  width: 100%;
}

.additionalAddress {
  width: 65%;
  margin-right: 0.5%;
}

.country {
  width: 35%;
  margin-left: 0.5%;
  padding-inline: 0%;
}

.selectorCountry {
  box-sizing: border-box;
  padding-inline: 1%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
}

.selectorCountry::-webkit-scrollbar {
  width: 6px;
}

.selectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.selectorOption {
  accent-color: #ed6723;
}

.selectorDate {
  box-sizing: border-box;
  padding-inline: 1%;
  width: 100%;
  height: 100%;
  font-size: small;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

.postalCode {
  width: 50%;
  margin-right: 0.5%;
}

.city {
  width: 50%;
  margin-left: 0.5%;
} */

.middleZoneEditext {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: small;
  padding-inline: 2%;
}

.sipsiInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  margin-bottom: 1.5%;
}

.sipsiId,
.sipsiPassword {
  box-sizing: border-box;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.sipsiButton {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-bottom: 7.5%;
}

.sipsiButton p {
  font-size: x-small;
  color: #ed6723;
  width: fit-content;
  height: fit-content;
}

.sipsiButton p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.directorInformationTopZone,
.directorInformationBottomZone {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.directorInformationTopZone {
  margin-bottom: 0.5%;
}

.directorLastName,
.directorFirstName {
  box-sizing: border-box;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.directorLastName {
  margin-right: 0.25%;
}

.directorFirstName {
  margin-left: 0.25%;
}

.directorDateOfBirth,
.directorPlaceOfBirth,
.directorCountryOfBirth {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33.33%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
}

.directorDateOfBirth {
  margin-right: 0.25%;
}

.directorPlaceOfBirth {
  margin-left: 0.25%;
  margin-right: 0.25%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-inline: 1%;
}

.directorCountryOfBirth {
  margin-left: 0.25%;
}

@media screen and (max-width: 2047px) and (max-height: 1103px) {
  .companyInformationContainer {
    height: 70%;
  }

  .companyInformationContainer h4 {
    font-size: 14px;
  }

  .companyInformationContainer p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1536px) and (max-height: 913px) {
  .companyInformationContainer {
    height: 70%;
  }

  .companyInformationContainer h4 {
    font-size: 14px;
  }

  .companyInformationContainer p {
    font-size: 12px;
  }
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .companyInformationContainer {
    overflow-y: auto;
  }

  .companyInformationContainer::-webkit-scrollbar {
    width: 6px;
  }

  .companyInformationContainer::-webkit-scrollbar-thumb {
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: grey;
  }

  .topContainer {
    height: 100%;
    padding-inline: 3.5%;
  }

  .middleContainer {
    flex-direction: column;
    height: 200%;
    padding-inline: 3.5%;
  }

  .bottomContainer {
    height: 100%;
    padding-inline: 3.5%;
  }

  .addressesDetails,
  .directorDetails {
    flex-direction: column;
  }

  .addressesDetailsBlock,
  .directorDetailsBlock {
    width: 100%;
  }

  #deliveryAddress {
    padding-bottom: 7.5%;
  }
}
