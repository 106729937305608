.headerContainer {
  position: relative;
  width: 100%;
  height: 10%;
  background-image: url('../../assets/images/backgrounds/background_social.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 1%;
  margin: 0 auto;
}

.asdSPWLogo {
  width: 60px;
  height: auto;
  margin-right: 2%;
  cursor: pointer;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .asdSPWLogo {
    width: 40px;
  }
}
