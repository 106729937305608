.inspectionDetailsContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 75%;
  height: 55%;
  top: 30%;
  left: 12.5%;
}

.inspectionDetailsDetailsAndEmployeesContainer,
.inspectionDetailsDocumentsContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  box-sizing: border-box;
  margin-inline: 0.25%;
}

.documentListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.documentListHeader p {
  transform: translateY(35%);
}

.deadlineEditButton {
  background: none;
  border: none;
  cursor: pointer;
}

.deadlineEditButton img {
  width: 20px;
  height: 20px;
}

.inspectionDetailsGlobalDetailsContainer,
.inspectionDetailsEmployeesContainer {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: auto;
  box-sizing: border-box;
  padding-inline: 2%;
  padding-block: 2%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.inspectionDetailsDetailsContainer {
  margin-block-end: 1%;
}

.inspectionDetailsEmployeesContainer {
  margin-block-start: 1%;
}

.inspectionDetailsDocumentsContainer {
  padding-inline: 1%;
  padding-block: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.inspectionDetailsContentArea,
.inspectionDetailsContentAreaDocuments {
  width: 100%;
  height: 100%;
  padding-inline: 1%;
  padding-block: 1%;
}

.inspectionDetailsContentAreaDocuments {
  margin-top: 2%;
  margin-bottom: 2%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.inspectionDetailsContentAreaDocuments::-webkit-scrollbar {
  width: 6px;
}

.inspectionDetailsContentAreaDocuments::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.popupContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.documentSpecs {
  box-sizing: border-box;
  padding: 1.5%;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.documentName,
.documentCategorySelector {
  display: flex;
  height: 100%;
  width: 25%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  padding: 1%;
}
