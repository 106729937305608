.endCustomerFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.endCustomerFormHeader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.endCustomerFormHeader i {
  margin-bottom: 2%;
}

.endCustomerFormTooltip {
  width: 20px;
  height: 20px;
  margin-left: 2%;
  font-size: 2px;
}

.endCustomerFormSubBlock {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5%;
  padding-inline: 1%;
}

.endCustomreFormTextInput {
  box-sizing: border-box;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
  width: 100%;
  padding: 2%;
}

.endCustomerFormAddressBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2%;
}
