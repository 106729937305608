.companiesListComponentContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 70%;
  top: 22.5%;
  left: 12.5%;
}

.companiesListGeneralActionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10%;
}

.companiesListResearchTextInput {
  display: flex;
  width: 30%;
  height: fit-content;
  padding: 1%;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.companiesListHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 5%;
  width: 100%;
  height: 10%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.companiesListFilterLogos {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.companiesListItemHeader,
.companiesListItemColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 30%;
  height: 100%;
}

.companiesListItemHeaderReduce,
.companiesListItemColumnReduce {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 20%;
  height: 100%;
}

.companiesListItemHeader,
.companiesListItemHeaderReduce {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.companiesListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 70%;
  overflow-y: auto;
}

.noCompaniesListItem,
.companiesListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 65px;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 25%;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.noCompaniesListItem {
  justify-content: center;
}

.companiesListItem {
  justify-content: space-between;
  padding-inline: 5%;
}

.companiesListActionsButtonHeader,
.companiesListActionsButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 40%;
  height: 100%;
}

.companiesListActionButton {
  background-color: #ed6723;
  border: #ed6723;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: white;
  padding: 10px;
  margin-right: 10px;
}

.companiesListFooterContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  cursor: pointer;
}

.pageLink {
  position: relative;
  display: block;
  color: #ed6723;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .pageLink {
    transition: none;
  }
}

.pageLink:hover {
  z-index: 2;
  color: #ed6723;
}

.pageLink:focus {
  z-index: 3;
  color: #ed6723;
  outline: 0;
}

.pageItem:not(:first-child) .pageLink {
  margin-left: -1px;
}

.pageItem.active .pageLink {
  z-index: 3;
  color: white;
  background-color: #ed6723;
  border-color: #ed6723;
  border-radius: 25%;
}

.pageItem.disabled .pageLink {
  color: #6c757d;
  cursor: default;
}

.pageLink {
  padding: 0.375rem 0.75rem;
}

.pageItem:first-child .pageLink {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pageItem:last-child .pageLink {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .pageLink {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .pageItem:first-child .pageLink {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .pageItem:last-child .pageLink {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .pageLink {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .pageItem:first-child .pageLink {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .pageItem:last-child .pageLink {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.itemsPerPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  color: #6c757d;
}

.itemsPerPage div {
  padding-inline: 5px;
}

.itemsPerPageButtonPressed,
.itemsPerPageButton {
  color: #ed6723;
  border: none;
  margin-right: 5px;
  cursor: pointer;
}

.itemsPerPageButtonPressed {
  background-color: #ed6723;
  color: white;
  padding: 8px 10px;
  border-radius: 25%;
}

.itemsPerPageButton {
  background-color: whitesmoke;
}
