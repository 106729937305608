.employeeDocumentSlot {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 14%;
  width: 100%;
  margin-bottom: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
}

.documentSlotEntitled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 4%;
  width: 25%;
  height: 100%;
}

.documentSlotEditButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.documentSlotDetails,
.documentSlotDetailsWithoutDocument {
  display: flex;
  flex-direction: row;
  align-items: right;
  height: 100%;
  width: 55%;
}

.documentSlotDetails {
  padding-top: 2%;
  justify-content: end;
}

.documentSlotDetailsWithoutDocument {
  align-items: center;
  justify-content: center;
}

.constructionCardDetailsButtonContainer,
.downloadButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.downloadButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.downloadButton:hover {
  text-decoration: underline;
}

.detailsLogo,
.downloadLogo,
.checkLogo {
  margin-left: 10px;
}

.checkLogo {
  width: 20px;
  height: 15px;
}

.detailsLogo {
  margin-top: 2.5px;
  width: 25px;
  height: 15px;
}

.downloadLogo {
  width: 20px;
  height: 20px;
}

.checkLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
}

.documentSlotEditButton {
  padding-top: 2%;
  height: 100%;
  width: 20%;
}

.updateDocument {
  padding-top: 2%;
  padding-bottom: 2%;
  height: 96%;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .updateDocument {
    height: 96%;
  }
}
