.missionsContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 70%;
  top: 22.5%;
  left: 5%;
}

.missionsHeaderContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  height: 10%;
  padding-inline: 0.5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.missionsHeaderReference,
.missionsHeaderInformation,
.missionsHeaderTags {
  display: flex;
  box-sizing: border-box;
  font-size: small;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.missionsHeaderReference {
  width: 15%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.missionsHeaderInformation {
  width: 10%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.missionsHeaderTags {
  width: 10%;
}

.missionsBodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
}

.missionsFooterContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-inline: 2%;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #ed6723;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.missionsFooterContainer span {
  box-sizing: border-box;
  padding-inline: 1%;
}

.missionsFooterContainer button {
  background-color: transparent;
  border: none;
  color: #ed6723;
}

.missionsFooterContainer button:enabled {
  cursor: pointer;
}

.missionsFooterContainer button:disabled {
  color: lightgray;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .missionsContainer p {
    font-size: x-small;
  }
}

.missionContainer {
  background-color: white;
  cursor: pointer;
}

.missionContainer,
.noMissionRegistered {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  background-image: url('../../assets/images/backgrounds/background_global.png');
  background-size: 35%;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.noMissionRegistered {
  background-color: white;
  justify-content: center;
  align-items: center;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.noMissionFind {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.missionReference,
.missionInformation,
.missionTags,
.openMissionDetails {
  display: flex;
  box-sizing: border-box;
  padding-inline: 2%;
  height: 100%;
  font-size: small;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.missionReference {
  width: 15%;
}

.missionInformation {
  width: 10%;
}

.missionTags {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 10%;
}

.openMissionDetails {
  width: 5%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.missionTagPaid,
.missionTagUnpaid,
.missionTagInspection,
.missionTagHandled,
.missionTagCompleted,
.missionTagInProgress,
.missionTagOnHold,
.missionTagIsInvalid {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  color: white;
  text-transform: uppercase;
}

.missionTagPaid,
.missionTagCompleted {
  background-color: green;
}

.missionTagHandled {
  background-color: dodgerblue;
}

.missionTagUnpaid {
  background-color: red;
}

.missionTagInspection {
  background-color: #ed6723;
}

.missionTagInProgress {
  background-color: #f8d105;
}

.missionTagOnHold {
  background-color: indianred;
}

.missionTagIsInvalid {
  background-color: grey;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .missionTagPaid,
  .missionTagUnpaid,
  .missionTagInspection,
  .missionTagHandled,
  .missionTagCompleted,
  .missionTagInProgress,
  .missionTagOnHold,
  .missionTagIsInvalid {
    font-size: 10px;
  }
}

.missionDetailsContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 70%;
  top: 22.5%;
  left: 5%;
}

.informationContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.globalInformationContainer,
.operatorContainer,
.endCustomerInformation,
.locationInformation,
.lodgingInformation {
  box-sizing: border-box;
  padding-top: 1%;
  padding-inline: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.endCustomerInformationHeader,
.locationInformationHeader,
.lodgingInformationHeader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.missionDetailsTooltip {
  width: 15px;
  height: 15px;
  margin-left: 2%;
  font-size: 2px;
}

.globalInformationContainer,
.operatorContainer {
  height: 100%;
}

.globalInformationContainer {
  width: 70%;
  margin-right: 0.25%;
  justify-content: space-between;
}

.operatorContainer {
  width: 30%;
  margin-left: 0.25%;
}

.globalAndOperatorInformationContainer,
.otherInformationContainer {
  display: flex;
  flex-direction: row;
  height: 50%;
  width: 100%;
}

.globalAndOperatorInformationContainer {
  margin-bottom: 0.25%;
}

.otherInformationContainer {
  margin-top: 0.25%;
}

.endCustomerInformation,
.locationInformation,
.lodgingInformation {
  height: 100%;
}

.endCustomerInformation {
  margin-right: 0.25%;
  width: 38%;
}

.locationInformation {
  margin-left: 0.25%;
  margin-right: 0.25%;
  width: 27%;
}

.lodgingInformation {
  margin-left: 0.25%;
  width: 35%;
}

.textAreaAlone,
.textArea {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-inline: 1%;
}

.textAreaAlone {
  width: 100%;
}

.textArea {
  width: 50%;
}

.textAreaButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.textAreaButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.textAreaButton:hover {
  text-decoration: underline;
}

.globalInformationPopUp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.globalInformationPopUpField {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 3%;
}

.globalInformationSelectorDate {
  box-sizing: border-box;
  padding: 1.5%;
  display: flex;
  width: 25%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1536px) and (max-height: 835px) {
  .missionDetailsContainer h4 {
    font-size: 14px;
  }
}

.employeesMissionDetailsContainer {
  display: flex;
  flex-direction: column;
}

.employeesMissionDetailsHeader,
.employeesMissionDetailsList {
  box-sizing: border-box;
  display: flex;
  padding-top: 4%;
  padding-inline: 4%;
}

.employeesMissionDetailsHeader {
  flex-direction: row;
  justify-content: space-between;
}

.employeesMissionDetailsList {
  flex-direction: column;
  justify-content: center;
}

.addLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.buttonsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  min-height: 6.5%;
}

.takeInChargeButton,
.informationDocumentButton {
  background-color: #ed6723;
  border: #ed6723;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: white;
  padding: 10px;
  height: 35px;
  margin-left: 5px;
}

.missionDetailsDownloadLogo {
  margin-left: 10px;
  width: 17.5px;
  height: 17.5px;
}

.operatorButtons {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
}

.takeInChargeLogo {
  margin-left: 10px;
  width: 12.5px;
  height: 12.5px;
}

.globalDetailsText,
.endCustomerText,
.lodgingText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 75%;
}

.globalDetailsText {
  width: 100%;
}

.endCustomerText,
.lodgingText {
  width: 100%;
}

.companyError p {
  color: red;
}

.space {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.saveButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.saveButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.saveButton:hover {
  text-decoration: underline;
}

.saveLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.searchFieldContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 0.5%;
}
.searchField {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 20%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7.5px;
  background-color: white;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .textArea {
    font-size: 11.5px;
  }
}

.paid {
  color: green;
}

.notPaid {
  color: red;
}
