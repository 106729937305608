.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.aboutContainer img {
  width: 25%;
  height: auto;
}

.aboutContainer p,
.aboutContainer img {
  padding-bottom: 5%;
}

.aboutContainer a {
  cursor: pointer;
  color: #ed6723;
  padding: 0.5%;
}

.aboutContainer a:hover {
  text-decoration: underline;
  color: #ed6723;
}

.contact {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
  margin-top: 10%;
}

.contact p {
  padding-bottom: 0%;
}

@media screen and ((max-width: 1127px) or (max-height: 649px)) {
  .aboutContainer p,
  .aboutContainer a {
    font-size: 12px;
  }

  .aboutContainer img {
    width: 33%;
    height: auto;
  }
}
