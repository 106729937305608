.detailsPopupContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.missionDetailsStartDate,
.missionDetailsEndDate,
.missionDetailsLodgingType {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.missionDetailsStartDate,
.missionDetailsEndDate {
  justify-content: space-between;
  width: 60%;
  padding-inline: 4%;
  font-size: 13px;
  padding-top: 2%;
}

.missionDetailsDatePicker {
  width: 200px;
  height: 40px;
  color: black;
  cursor: pointer;
  font-size: small;
  align-items: center;
  background-color: white;
  margin-left: 2%;
  margin-right: 5%;
  .react-date-picker__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5%;
  }
}

.missionDetailsLodgingType {
  width: 80%;
  padding-top: 4%;
}

.lodgingTypeSelectorContainer {
  width: 75%;
  padding-inline-start: 15%;
  height: 100%;
}

.lodgingTypeSelector {
  box-sizing: border-box;
  padding: 4%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
  cursor: pointer;
}

.lodgingAddressInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  margin-bottom: 1.5%;
}

.lodgingMainAddress,
.lodgingAdditionalAddress,
.lodgingCountry,
.lodgingPostalCode,
.lodgingCity {
  box-sizing: border-box;
  padding: 1.5%;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.lodgingMainAddress {
  width: 100%;
}

.lodgingAdditionalAddress {
  width: 65%;
  margin-right: 0.5%;
}

.lodgingCountry {
  width: 50%;
  margin-left: 0.5%;
}

.lodgingSelectorCountry {
  box-sizing: border-box;
  padding-inline: 1%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
  cursor: pointer;
}

.lodgingSelectorCountry::-webkit-scrollbar {
  width: 6px;
}

.lodgingSelectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.lodgingPostalCode {
  width: 35%;
  margin-left: 0.5%;
}

.lodgingCity {
  width: 50%;
  margin-right: 0.5%;
}

.firstBlock {
  display: flex;
  flex-direction: row;
  accent-color: #ed6723;
}

.civilityOptionsLabel {
  margin-left: 4%;
}

.secondBlock,
.thirdBlock,
.fourthBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.secondBlockFields,
.thirdBlockFields,
.employeeMainAddress,
.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  box-sizing: border-box;
  display: flex;
  padding-top: 3%;
  justify-content: space-between;
}

.secondBlockFields,
.thirdBlockFields,
.fourthBlock,
.employeeMainAddress {
  flex-direction: column;
}

.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  flex-direction: row;
}

.secondBlockFields,
.thirdBlockFields {
  width: 30%;
}

.employeeMainAddress {
  width: 100%;
}

.employeeAdditionalAddress {
  width: 57.5%;
}
.employeePostalCode {
  width: 37.5%;
}

.employeeCountry,
.employeeCity {
  width: 47.5%;
}

.employeeCountryOfBirth {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.employeeCountry {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.secondBlockFieldsEditText,
.thirdBlockFieldsEditText,
.fourthBlockFieldsEditText,
.selectorDate,
.selectorCountry,
.employeeMainAddressEditText,
.employeeAdditionalAddressEditText,
.employeePostalCodeEditText,
.employeeCityEditText {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.selectorDate {
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

.selectorCountry::-webkit-scrollbar {
  width: 6px;
}

.selectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

@media screen and ((max-width: 1920px) or (max-height: 960px)) {
  .detailsPopupContent p,
  .detailsPopupContent label {
    font-size: 12px;
  }
}

.companyPopupContainer,
.companyGlobalInformationContainer,
.companyGlobalInformationSubContainer {
  display: flex;
  height: 100%;
  padding: 2%;
}

.companyPopupContainer,
.companyGlobalInformationContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.companyGlobalInformationSubContainer {
  width: 50%;
}

.companyPopupContainer,
.companyGlobalInformationSubContainer {
  flex-direction: column;
}

.companyGlobalInformationContainer {
  flex-direction: row;
}

.companyPopupFieldToUpdate {
  width: 100%;
  padding-bottom: 5%;
}

.companyEditAndTooltipContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.updateCompanyGlobalField,
.updateCompanyEditTextField,
.updateCompanyDirectorTextField,
.updateDirectorDate,
.directorCountrySelector {
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.updateCompanyEditTextField,
.updateCompanyDirectorTextField,
.directorCountrySelector {
  width: 45%;
}

.updateCompanyGlobalField {
  width: 90%;
  padding: 4%;
}

.updateCompanyEditTextField,
.directorCountrySelector {
  padding: 2%;
}

.updateCompanyDirectorTextField {
  padding: 4%;
}

.directorCountrySelector option {
  background-color: white;
  color: black;
}

.directorCountrySelector option:checked {
  background-color: lightgray;
  color: black;
}

.directorCountrySelector::-webkit-scrollbar {
  width: 6px;
}

.directorCountrySelector::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.updateCompanyDirectorTextField,
.updateDirectorDate {
  width: 90%;
}

.updateDirectorDate {
  .react-date-picker__wrapper {
    border: none;
    padding: 3%;
  }
}

.createEmployeePopup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.civilityBlock {
  display: flex;
  flex-direction: row;
  accent-color: #ed6723;
}

.civilityOptionsLabel {
  margin-left: 4%;
}

.namesAndNationalityBlock,
.birthBlock,
.createEmployeeAddressBlock {
  display: flex;
  justify-content: space-between;
}

.namesAndNationalityBlock,
.birthBlock {
  flex-direction: row;
}

.createEmployeeAddressBlock {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.namesAndNationalityBlockFields,
.birthBlockFields {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  padding-top: 3%;
}

.firstName,
.lastName,
.nationality,
.selectorBirthDate,
.birthPlace,
.selectorBirthCountry {
  box-sizing: border-box;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
}

.firstName,
.lastName,
.nationality,
.birthPlace {
  padding: 5%;
}

.selectorBirthDate {
  padding: 2%;
  .react-date-picker__wrapper {
    border: none;
    border-radius: 10px;
  }
}

.selectorBirthCountryDiv {
  box-sizing: border-box;
  display: flex;
  height: 100%;
}

.selectorBirthCountry {
  width: 100%;
  padding-inline-start: 3%;
}

.selectorBirthCountry::-webkit-scrollbar {
  width: 6px;
}

.selectorBirthCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.employeeMainAddress,
.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  box-sizing: border-box;
  display: flex;
  padding-top: 3%;
  justify-content: space-between;
}

.employeeAdditionalAddressAndCountry,
.employeePostalCodeAndCity {
  flex-direction: row;
}

.employeeMainAddress {
  width: 100%;
}

.employeeAdditionalAddress {
  width: 57.5%;
}
.employeePostalCode {
  width: 37.5%;
}

.employeeCountry,
.employeeCity {
  width: 47.5%;
}

.employeeCountryOfBirth {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.employeeCountry {
  box-sizing: border-box;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.companyTooltip {
  width: 2.5%;
  height: 2.5%;
  margin-left: 2%;
  font-size: 2px;
}

.companyGlobalTooltip {
  width: 5%;
  height: 5%;
  margin-left: 4%;
  font-size: 4px;
}

.inlineBlocks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.companyAddressInformation {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.companyMainAddress,
.companyAdditionalAddress,
.companyCountry,
.companyPostalCode,
.companyCity {
  box-sizing: border-box;
  padding: 1%;
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.companyMainAddress {
  width: 100%;
}

.companyAdditionalAddress {
  width: 100%;
  margin-right: 0.5%;
}

.companyCountry {
  width: 100%;
  margin-left: 0.5%;
  padding-inline: 0%;
}

.companySelectorCountry {
  box-sizing: border-box;
  padding: 2%;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: small;
  accent-color: #ed6723;
  background-color: white;
  cursor: pointer;
}

.companySelectorCountry::-webkit-scrollbar {
  width: 6px;
}

.companySelectorCountry::-webkit-scrollbar-thumb {
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
}

.companyPostalCode {
  width: 100%;
  margin-left: 0.5%;
  padding: 1.75%;
}

.companyCity {
  width: 100%;
  margin-right: 0.5%;
}

.subtitlesBlocks,
.subtitlesBlocksAdditional,
.subtitlesBlocksPostal,
.subtitlesBlocksBottom {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5%;
  padding-inline: 1%;
}

.subtitlesBlocks {
  width: 100%;
}

.subtitlesBlocksAdditional {
  width: 65%;
}

.subtitlesBlocksPostal {
  width: 35%;
}

.subtitlesBlocksBottom {
  width: 50%;
}

.updateAddresses {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.companyError {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.companyError p {
  color: red;
}

.space {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.saveButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.saveButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.saveButton:hover {
  text-decoration: underline;
}

.saveLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.updateLocationName {
  box-sizing: border-box;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  font-size: 12px;
  width: 100%;
  padding: 2%;
  margin-bottom: 3%;
}
