.addButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.addButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.addButton:hover {
  text-decoration: underline;
}

.addLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
