body {
  margin: 0;
  font-family: 'Muli-Regular', 'DrukTextWide-Bold', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Muli-Regular';
  src: url('./spw/src/assets/fonts/Muli/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DrukTextWide-Bold';
  src: url('./spw/src/assets/fonts/DrukText/DrukTextWide-Bold.otf')
    format('opentype');
}

p,
span,
div,
button,
option,
select {
  font-family: 'Muli-Regular', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'DrukTextWide-Bold', sans-serif;
}

#obligatoire {
  color: red;
}

._IYz6Z {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

._4GdcU {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: small;
}

._4GdcU:hover {
  cursor: text;
  background-color: transparent;
}

@media screen and (max-width: 1536px) and (max-height: 826px) {
  ._4GdcU {
    font-size: x-small;
  }
}
