.editButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.editButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
  color: #ed6723;
}

.editButton:hover {
  text-decoration: underline;
}

.editLogo {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
